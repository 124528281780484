/* global Component */
class addSOFavModalComponent extends Component {

    static name() {
        return "addSOFavModalComponent";
    }

    static componentName() {
        return "addSOFavModalComponent";
    }

    getProps() {
        return  ['from'];
    }

    data() {
        return {
            alertError: null,
            FavOrder: {},
            FavouriteName: null,
        };
    }

    created() {
        return function () {
            this.FavouriteName = null;
        };
    }

    getComputed() {
        return {
            itemsList: function () {
                if (this.from == 'so-items')
                    return this.$store.getters.getCurrentOrder;

                else if (this.from == 'car-items')
                    return this.$store.getters.getItemCar;
            },
            items: function () {
                let items = [];
                if (this.itemsList){
                    if (this.from == 'so-items') {
                        for (let i of this.itemsList.fields.Items) {
                            items.push([i.fields.ArtCode, i.fields.Qty]);
                        }
                    } else if (this.from == 'car-items') {
                        for (let i of this.itemsList) {
                            items.push([i.id, i.cant]);
                        }
                    }
                }
                return items;
            }
        };
    }

    getMethods() {
        return {
            toggleFavouriteOrder:this.toggleFavouriteOrder,
            closeModal: this.closeModal,
        };
    }

    toggleFavouriteOrder(OrderSerNr){
        this.alertError = null;
        this.FavOrder = {name:this.FavouriteName,items:this.items,OrderSerNr:OrderSerNr.toString(),from:this.from};

        if (!this.FavouriteName)
            this.alertError = 'Please, fill the name field';
        if (this.$store.getters.getFavouriteOrderByName(this.FavouriteName))
            this.alertError = 'There is already a favorite order with that name';
        if(!this.alertError) {
            this.$store.dispatch('toggleFavouriteOrder', this.FavOrder);
            this.FavouriteName = null;
            this.closeModal();
        }
    }

    closeModal() {
        $("#addSOFavModal").modal('toggle');
    }

    getTemplate() {
        return `<div class="modal fade item-detail-modal" id="addSOFavModal" tabindex="-1" role="dialog">
                  <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title">{{tr("Add To Favorite Orders")}}</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="FavouriteName=null">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <div class="modal-body" v-if="itemsList">
                                <div class="col-sm-12">
                                      <div ref='errorFVForm' class="alert alert-danger alert-dismissible fade show" role="alert" v-if="alertError">
                                           <strong>{{tr(alertError)}}!</strong>
                                           <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertError = null">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                       </div>
                                </div>
                               <div class="form-group">
                                      <label class="col-sm-4 control-label">{{tr("Save as")}}</label>
                                      <div class="col-sm-8">
                                        <input class="form-control" v-model="FavouriteName" id="nameFavouriteOrder" type="text">
                                      </div>
                               </div>
                               <h5 class="title">
                                    <span v-if="from == 'so-items'" class="center">{{tr('Sales Order')}} {{itemsList.fields.SerNr}}</span>
                                    <span v-else class="center">{{tr('Favorite Order Items')}}</span>
                              </h5>
                               <table class="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>{{tr('Code')}}</th>
                                        <th>{{tr('Products')}}</th>
                                        <th>{{tr("Qty")}}</th>
                                      </tr>
                                    </thead>
                                    <template v-if="from == 'so-items'">
                                        <tbody>
                                        <tr v-for="item of itemsList.fields.Items ">
                                            <td>{{item.fields.ArtCode}}</td>
                                            <td>{{item.fields.Name}}</td>
                                            <td>{{item.fields.Qty}}</td>
                                        </tr>
                                        </tbody>
                                    </template>
                                    <template v-if="from == 'car-items'">
                                        <tbody>
                                        <tr v-for="item of itemsList">
                                            <td>{{item.id}}</td>
                                            <td>{{item.item.Name}}</td>
                                            <td>{{item.cant}}</td>
                                        </tr>
                                        </tbody>
                                    </template>
                               </table>
                               <div class="text-right">
                                   <button class="btn secondary-btn" @click="toggleFavouriteOrder((from == 'so-items')?itemsList.fields.SerNr:'')"><i class="icon fas fa-save"></i>{{tr('Save')}}</button>
                               </div>
                          </div>
                      </div>
                  </div>
              </div>`;
    }
}

addSOFavModalComponent.registerComponent();